<template>
    <div class="all">
        <div class="top">
            <div class="toptitle">
                已选产品 <span>(用户只可择一兑换)</span>
            </div>
            <div class="detail">
                <div class="commitem" v-for="(item, index) in list">
                    <div class="left">
                        <div class="left_title">
                            <img :src="item.IMAGE" alt="" style="width: 60px; height: 70px; margin-right: 10px;"
                                class="seimg">
                            <div class="select">
                                选项{{ index + 1 }}
                            </div>
                        </div>
                        <div class="title">
                            <div class="title_name">{{ item.TITLE }}</div>
                            <div class="part">1份</div>
                        </div>
                    </div>
                    <div class="right">
                        <div class="price">￥{{ item.PRICE }}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="middle">
            <van-form>
                <van-cell-group inset>
                    <van-field name="stepper" input-align="right">
                        <template #label>
                            <div class="text">生成数量</div>
                        </template>
                        <template #input>
                            <van-stepper v-model="number" theme="round" button-size="22" />
                        </template>
                    </van-field>

                    <van-field name="stepper" input-align="right">
                        <template #label>
                            <div class="text">可选数量</div>
                        </template>
                        <template #input>
                            <van-stepper v-model="aNum" theme="round" button-size="22" :max="list.length"
                                disable-input />
                        </template>
                    </van-field>

                    <van-field v-model="result" is-link readonly name="timer" @click="showPicker = true"
                        input-align="right">
                        <template #label>
                            <div class="text">有效期</div>
                        </template>
                    </van-field>

                    <van-calendar v-model:show="showPicker" @confirm="onConfirm" :max-date="maxDate" />

                    <van-field name="switch" input-align="right">
                        <template #label>
                            <div class="text">短信通知</div>
                        </template>
                        <template #input>
                            <van-switch v-model="checked" size="20px" inactive-color="#F7F7F7" active-color="#FF4E34" />
                        </template>
                    </van-field>

                    <van-field name="checkbox" input-align="right">
                        <template #label>
                            <div class="text">自定义域名</div>
                        </template>
                        <template #input>
                            <van-checkbox checked-color="#FF4E34" v-model="ischecked" @change="custom" />
                        </template>
                    </van-field>
                    <van-field v-show="isshow">
                        <template #input>
                            <input type="text" v-model="text" disabled placeholder="支持自定义域名，具体使用方式请联系我司商务" class="inputctm">
                        </template>
                    </van-field>

                    <van-field name="checkbox" input-align="right" label-width="87%">
                        <template #label>
                            <div class="bottom">若您对价格波动敏感，可设置限价，超过限价系统不进行成交</div>
                        </template>
                        <template #input>
                            <van-checkbox checked-color="#FF4E34" v-model="ischecked2" @change="custom2" />
                        </template>
                    </van-field>
                    <van-field v-show="isshow2">
                        <template #input>
                            <input type="number" v-model="price" placeholder="请输入限价" class="inputctm">
                        </template>
                    </van-field>
                </van-cell-group>
            </van-form>
        </div>
        <div class="footer">
            <van-button round size="large" @click="submit" class="sub">立即生成</van-button>
        </div>
    </div>
</template>
<script>
import setmeal from '@/api/setmeal';
import change from '@/api/change';
import tool from '@/util/tool';
import { Toast } from 'vant';
export default {
    data() {
        return {
            number: 1,
            aNum: 1,
            showPicker: false,
            result: '',
            isshow: true,
            isshow2: false,
            checked: false,
            ischecked: true,
            ischecked2: false,
            text: "https://z2.mdlvip.vip/?s=",
            list: [],
            idlist: [],
            price: null,
            setmealslist: [],
            maxDate: new Date(new Date().getFullYear(), new Date().getMonth() + 4, new Date().getDate()),
        }
    },
    methods: {
        onConfirm(selectedValues) {
            this.result = selectedValues.toLocaleString().split(" ")[0];
            this.showPicker = false;
        },
        submit() {
            if (this.number == "" || this.result == "" || this.aNum == "" || !this.text) {
                Toast("请将信息填写完整");
            } else {
                this.list.forEach(item => {
                    this.setmealslist.push(item.ID)
                })

                const obj = {
                    setmeals: this.setmealslist,
                    cdkNum: this.number,
                    anum: this.aNum,
                    dateline: this.result.split("/")[0] + '-' + this.result.split("/")[1] + "-" + this.result.split("/")[2],
                    note: this.checked ? 1 : 0,
                    domainName: this.text,//+"/"
                    price: this.price,
                    userId: tool.data.get('USER_INFO').id
                }
                if (obj.anum === 1 || obj.anum === this.list.length) {
                    // console.log(obj);
                    change.generate(obj).then(res => {
                        this.$router.replace(`/order`);
                    })
                } else {
                    Toast("可选数量只能是1或全部");
                }
            }
        },
        custom() {
            this.ischecked ? this.isshow = true : this.isshow = false
        },
        custom2() {
            this.ischecked2 ? this.isshow2 = true : this.isshow2 = false
        }
    },
    created() {
        this.idlist = JSON.parse(this.$route.query.value)
        this.idlist.forEach(item => {
            setmeal.getcdkdetail({ smId: item }).then(res => {
                this.list.push(res[0]) 
            })
        })
        
    }
}
</script>

<style lang="less" scoped>
.all {
    background-color: #F7F7F7;
    height: 100%;
    width: 100%;
    position: absolute;
    overflow: auto;
}

.top {
    width: 90%;
    margin: 0 auto;
    border-radius: 30px;
    padding-top: 30px;

    .detail {
        background-color: white;
        border-radius: 0 0 12px 12px;
    }


    .toptitle {
        background: linear-gradient(90deg, #FF7834 0%, #FF4E34 100%);
        height: 40px;
        line-height: 40px;
        color: white;
        border-radius: 12px 12px 0 0;
        padding-left: 17px;

        span {
            font-size: 14px;
        }
    }

    .commitem {
        display: flex;
        justify-content: space-around;
        padding: 20px 15px 20px 15px;

        :n-1 {
            border-bottom: 1px solid #F7F7F7;
        }

        .left {
            display: flex;
            position: relative;
            // flex: 4;
            width: 90%;

            .select {
                position: absolute;
                left: 0px;
                top: 0;
                width: 34px;
                height: 14px;
                border-radius: 0px 6px 0px 6px;
                background: #FF4F34;
                color: white;
                font-size: 9px;
                text-align: center;
            }

            .title {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                overflow: hidden;

                .part {
                    font-size: 12px;
                }

                .title_name {
                    width: 100%;
                    font-size: 13px;
                    font-weight: bold;
                }
            }
        }

        .right {
            .price {
                font-size: 14px;
                font-weight: bold;
            }
        }
    }
}

.van-cell.van-field {
    padding: 13px 13px 13px 13px;
}

.middle {
    margin: 0 auto;
    border-radius: 12px;
    margin-top: 20px;

    .text {
        color: black;
        font-weight: bold;
        font-size: 12px
    }
}

.bottom {
    color: #FF4E34;
    font-size: 12px;
}

.inputctm {
    padding: 6px;
    background-color: #F4F4F4;
    border-radius: 7px 7px 7px 7px;
    width: 100%;
    list-style: none;
    border-style: none;
    font-size: 12px;
}

.footer {
    width: 90%;
    margin: 0 auto;
    margin-top: 20px;

    .sub {
        background: linear-gradient(266deg, #FF4E34 0%, #FF7834 100%);
        color: white;
        height: 40px;
        line-height: 40px;

    }
}
</style>